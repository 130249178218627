import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { ErrorBoundary } from "react-error-boundary"
import Obfuscate from "react-obfuscate"

import Header from "./header"
import "./layout.scss"
import {logError} from "../services/log"

function ErrorFallback({ error }) {
  return (
    <div className="container">
      <div role="alert">
        <div className="mt-5">Agro Bio Conso</div>
        <h1>Oups, une erreur est survenue</h1>
        <p>Veuillez nous excuser pour les désagréments. Si l&apos;erreur persiste, merci de nous contacter : <Obfuscate email="nbland@aprogsys.com" /></p>
        <pre style={{ display: "none" }}>{error.message}</pre>
        <button onClick={() => window.location.reload()}>Recharger la page</button>
      </div>
    </div>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.string,
  resetErrorBoundary: PropTypes.func,
}

function myErrorHandler (error, componentStack) {
  logError("erreur sur la page", {error, componentStack});
}

const Layout = ({ children }) => {
  return (
    <ErrorBoundary onError={myErrorHandler} FallbackComponent={ErrorFallback}>
      <Header/>
      <div>
        <main>{children}</main>
        <footer className="footer">
          <div className="content has-text-centered">
            <Link to="/legal">Mentions légales</Link> - <Link to="/statuts">Statuts</Link> - <Link to="/donnees-personnelles">Données personnelles</Link> - <a href="/tutoriel-creation-compte.pdf" _target="blank">Manuel utilisateur</a> - <Link to="/contact">Nous contacter</Link>
          </div>
        </footer>
      </div>
    </ErrorBoundary>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
