import { SlackOAuthClient } from "messaging-api-slack"

export const logError = (message, data = []) => {
  if (process.env.NODE_ENV === "production" && typeof window !== "undefined") {
    const client = new SlackOAuthClient({
      accessToken: "xoxp-12970109927-12994999092-596958357282-d0b1be921f8a7171b658913cd5e2b9ef",
    });
    return client.callMethod("chat.postMessage", {
      channel: "server-alerts",
      text: '*Agrobio:* ' + message,
      attachments: JSON.stringify([{text: JSON.stringify({...data, url: window.location.href })}]),
      as_user: false,
      username: "AgroBio",
    });
  }
  return new Promise(resolve => resolve(true));
}