import { Link } from "gatsby"
import React, {useState, useEffect} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUser,
  faSignOutAlt,
  faSignInAlt,
  faSeedling,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { logout } from "../actions"
import { graphql, useStaticQuery } from "gatsby" 
import { setPickupPoint } from "../actions/panier"

export const Header = props => {
  const identifiants = useSelector(s => s.identifiants)
  const panier = useSelector(s => s.panierR)
  const dispatch = useDispatch()
  const [isMenuActive, setIsMenuActive] = useState(0);
  const [favoriteZoneId, setFavoriteZoneId]   = useState("")
  const [favoriteZoneName, setFavoriteZoneName]   = useState("")

  const dataGroup = useStaticQuery(graphql`
    query dataHeader {
      allMysqlGroupe {
        edges {
          node {
            group_id
            group_zone_id
          }
        }
      }
      allMysqlZone {
        edges {
          node {
            zone_info
            zone_id
          }
        }
      }
    }
  `) 

  useEffect(() => {
    if (typeof window !== "undefined") {
      if(identifiants.identifiants !== null && identifiants.identifiants.lastOrders && identifiants.identifiants.lastOrders.length > 0){
        let zoneIdSelected = ""
        let zoneNameSelected = ""
        zoneIdSelected = dataGroup.allMysqlGroupe.edges.find(legroupe => parseInt(legroupe.node.group_id) === parseInt(identifiants.identifiants.lastOrders[0].cmd_group_id))
        zoneIdSelected = zoneIdSelected ? zoneIdSelected.node.group_zone_id : null
        if (zoneIdSelected !== favoriteZoneId) setFavoriteZoneId(zoneIdSelected);
        zoneNameSelected = dataGroup.allMysqlZone.edges.find(lazone => parseInt(lazone.node.zone_id) === parseInt(zoneIdSelected))
        zoneNameSelected = zoneNameSelected ? zoneNameSelected.node.zone_info : null
        if(zoneNameSelected !== favoriteZoneName) setFavoriteZoneName(zoneNameSelected)
        if (!panier.pickupPoint && identifiants.identifiants.lastOrders[0].cmd_group_id) {
          dispatch(setPickupPoint(identifiants.identifiants.lastOrders[0].cmd_group_id));
        }
      } 
    }
  }, [dataGroup,dispatch,identifiants, favoriteZoneId, favoriteZoneName, panier.pickupPoint])

  return (
    <header style={{ marginBottom: "1rem" }}>
      <nav
        className="navbar is-success is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container is-fluid">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item dreamland is-size-3 mr-2" href="/">
              <FontAwesomeIcon icon={faSeedling} className="mr-2" /> AGRO BIO CONSO
            </Link>

            <div
              role="button"
              className={`navbar-burger burger${isMenuActive ? " is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="mainNavbar"
              tabIndex="-2" onKeyPress={() => {}}
              onClick={() => setIsMenuActive(!isMenuActive)}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>

          <div id="mainNavbar" className={`navbar-menu ${isMenuActive ? " is-active" : ""}`}>
            <div className="navbar-start">
              <Link to={"/association"} className="navbar-item">
                L'association
              </Link>
              <Link to={"/producteur"} className="navbar-item">
                Les producteurs
              </Link>
              <Link to={"/#points-retrait"} className="navbar-item">
                Points de retrait
              </Link>
              {identifiants.identifiants && identifiants.identifiants !== null && favoriteZoneId && favoriteZoneId !== ""  ?  ( //aller selectionner la zone de la commande la plus recente (avant la passer dans la commande), et dispatch le point de retrait
                <Link to={`/producteur/${favoriteZoneId}-${favoriteZoneName}/produits`} className="navbar-item">
                  Produits
                </Link>
              ) : null}
            </div>
            <div className="navbar-end">
              {panier.products && panier.products.length > 0 ? (
                <Link to={`/panier`} className="navbar-item almond is-size-4">
                  <FontAwesomeIcon icon={faShoppingBag} className="mr-2" />
                  {identifiants.identifiants && identifiants.identifiants.currentCmdEndInput && identifiants.identifiants.currentCmdEndInput.length ? 'Ma commande' : 'Mon panier'} <span className={`counter ${identifiants.identifiants && identifiants.identifiants.currentCmdEndInput && identifiants.identifiants.currentCmdEndInput.length ? 'is-confirmed' : ''}`}>{panier && panier.products.length}</span>
                </Link>
              ) : (
                ""
              )}
              {identifiants.identifiants !== null ? (
                <>
                  <Link to="/apps/dashboard/" className="navbar-item almond is-size-4">
                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                    Mon compte
                  </Link>
                  <Link
                    to="/"
                    className="navbar-item almond is-size-4"
                    onClick={() => dispatch(logout())}
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                    Déconnexion
                  </Link>
                </>
              ) : (
                <Link to={`/connexion`} className="navbar-item almond is-size-4">
                  {/* <Link to={`/connexion?redirect=${props.location.pathname}`}> */}
                  <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
                  Connexion
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
